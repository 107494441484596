<template>
  <v-container fluid>
    <Tabla
      :items="{ headers, garantias, titulo: 'Garantías activadas' }"
      @actualizar="actualizar"
    ></Tabla>
  </v-container>
</template>

<script>
import PostService from "../../PostService";
import Tabla from "../../components/admin/Tabla";
export default {
  name: "admin-garantias",
  components: {
    Tabla
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_formato",
        actions: true
      },
      { text: "Comercial", value: "distribuidor" },
      { text: "Teléfono", value: "telefono" },
      { text: "Email", value: "email" },
      { text: "Número Serie", value: "numero_serie" },
      { text: "Modelo", value: "modelo" },
      { text: "Fecha Venta", value: "fecha_venta" },
      // { text: "Kilometraje", value: "kilometraje" },
      // { text: "Número Cupon", value: "numero_cupon" },
      // { text: "Número parte uno", value: "numero_parteuno" },
      // { text: "Descripción", value: "descripcion" },
      // { text: "Cantidad", value: "cantidad" },
      // { text: "Reporte de falla", value: "reporte_falla" },
      // { text: "Imágen", value: "imagen_falla" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    garantias: []
  }),
  created() {
    this.actualizar();
  },
  methods: {
    async actualizar() {
      this.garantias = [];
      const logged = this.$store.getters.token;
      if (logged === "") return this.$router.push({ name: "admin" });
      const datosGarantias = await PostService.getFormatoGarantia(logged);
      this.garantias = datosGarantias.data;
    }
  }
};
</script>

<style></style>
